.custom-marker-cluster {
  background: #ffffff;
  border: 5px solid #000000;
  border-radius: 50%;
  color: #000000;
  line-height: 37px;
  text-align: center;
  width: 36px;
  height: 36px;
  font-weight: bold;
  font-size: 20px;
}

@keyframes antPathAnimation {
  from {
    stroke-dashoffset: 1000;
  }

  to {
    stroke-dashoffset: 0;
  }
}

.ant-path {
  position: relative;
  stroke-dasharray: 250 7.5;
  animation: antPathAnimation 60s linear infinite;
}

@keyframes antPathCurrentAnimation {
  from {
    stroke-dashoffset: 1000;
  }

  to {
    stroke-dashoffset: 0;
  }
}

.ant-path-current {
  position: relative;
  stroke-dasharray: 4 4;
  animation: antPathCurrentAnimation 60s linear infinite;
}

.animated-circle-marker {
  position: relative;
  stroke-dasharray: 8 8;
  animation: animatedCircleMarkerAnimation 60s linear infinite;
  /* Renamed animation to avoid conflicts */
}

.animated-circle-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 5px solid transparent;
  /* Initial transparent border */
  border-radius: 50%;
  animation: animatedCircleMarkerAnimation 60s linear infinite;
  /* Renamed animation to avoid conflicts */
}

@keyframes animatedCircleMarkerAnimation {
  from {
    stroke-dashoffset: 0;
    border-image-source: linear-gradient(0deg, transparent, #000);
    /* Gradient for border animation */
  }

  to {
    stroke-dashoffset: 1000;
    border-image-source: linear-gradient(0deg, transparent, #000);
    /* Gradient for border animation */
  }
}